/* NotFound.css */
.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7fafc;
}

.content {
  text-align: center;
}

.title {
  font-size: 2.25rem;
  font-weight: bold;
  color: #2d3748;
}

.subtitle {
  font-size: 1.25rem;
  color: #718096;
}
