.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
}

.video-platform {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
}

.main-content {
  display: flex;
  margin-top: 20px;
  padding-left: 5%;
  padding-right: 5%;
}

.left-section {
  flex: 3;
  margin-right: 20px;

  padding: 5px;
}

.video-player {
  border-radius: 15px;
  position: relative;
  background-color: transparent;
  padding: 0;
  height: auto;
  overflow: hidden;

  aspect-ratio: 16 / 9;
}

.react-player__play-icon {
  filter: hue-rotate(0deg) brightness(0) saturate(100%) !important;
  /* Adjust the filter values to achieve the desired red shade */
}

.video-player .react-player {
  background-color: transparent;
  top: 0;
  left: 0;
}

.video-player .react-player video {
  object-fit: contain;
  background-color: #333;
}

.video-details {
  padding-left: 5%;
  padding-right: 30%;
  border-radius: 8px;
}

.video-title {
  font-size: 24px;
  margin-bottom: 8px;
}

.channel-video-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel-data {
  display: flex;
  align-items: center;
}

.channel-logo {
  max-height: 40px;
  max-width: 40px;
}

.channel-logo img {
  max-width: 100%;
  height: auto;
}

.channel-name {
  font-weight: bold;
  padding: 5px;
}

.youtube-buttons {
  display: flex;
  align-items: center;
}

.like-button,
.dislike-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: color 0.1s ease;
}

.like-button span {
  padding-left: 5px;
}

.like-button:hover,
.dislike-button:hover {
  opacity: 0.7;
}

.liked,
.disliked {
  color: red; /* Red color when active */
}

/* Default colors when not active */
.like-button:not(.liked),
.dislike-button:not(.disliked) {
  color: #606060;
}

.views {
  color: #606060;
  font-size: 14px;
  margin-top: 8px;
  font-weight: bold;
}

.description.collapsed {
  margin-bottom: 4px;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.description.expanded {
  margin-bottom: 4px;
  white-space: pre-wrap;
}

.toggle-button {
  background-color: #333; /* Blue color */
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 8px; /* Space between description and button */
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #606060; /* Darker blue on hover */
}
.description.expanded {
  margin-bottom: 4px;
  white-space: pre-wrap;
}

.comments-section {
  padding-left: 5%;
  padding-right: 30%;
  border-radius: 8px;
}

.comments .name-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.comments textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
}

.comments button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.comments button:hover {
  background-color: #606060;
}

.comments-list {
  margin-top: 15px;
}

.comment-item {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.video-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
}

.video-item {
  cursor: pointer;
  transition: transform 0.2s ease;
  max-width: 300px;
  max-height: 300px;
  position: relative;
}

.video-item:hover {
  transform: scale(1.03);
}

.thumbnail-container {
  position: relative;
}

.thumbnail {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
  border-radius: 15px;
  margin-bottom: 8px;
  aspect-ratio: 16 / 9;
}

.duration {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: black;
  color: white;
  padding: 2px 6px;
  border-radius: 0 0 15px 0px;
  font-size: 12px; /* Adjust font size to your preference */
}

.video-info h3 {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.video-info p {
  font-size: 12px;
  color: #757575;
  display: none; /* Hide the paragraph as the duration is now part of the thumbnail */
}

@media (max-width: 1100px) {
  .main-content {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
  }

  .video-player {
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .video-player .react-player video {
    object-fit: contain !important;
  }

  .left-section {
    margin-right: 0;
  }

  .right-section {
    margin-top: 20px;
  }

  .video-list {
    flex-direction: row;
    justify-content: space-between;
  }

  .video-item {
    max-width: 45%;
  }

  .video-details {
    padding-right: 5%;
  }

  .comments-section {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0;
  }

  .left-section {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .right-section {
    width: 100%;
  }

  .video-player {
    height: auto;
    aspect-ratio: 16 / 9;
  }

  .video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Ensure even distribution and use of space */
    gap: 10px;
  }

  .comments .name-input,
  .comments textarea {
    width: calc(100% - 20px);
  }

  .video-details {
    padding-left: 3%;
    padding-right: 3%;
  }

  .comments-section {
    padding-left: 3%;
    padding-right: 3%;
  }
}

/* Adjustments for smaller screens */
@media (max-width: 480px) {
  .video-title,
  .channel-name,
  .views,
  .description {
    font-size: smaller;
  }

  .comments-section,
  .comments-list,
  .comments .name-input,
  .comments textarea,
  .comments button {
    font-size: smaller;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
    color: #ffffff;
  }

  .video-platform {
    color: #ddd;
  }

  .main-content {
    display: flex;
  }

  .video-details {
    color: #ccc;
  }

  .channel-name {
    color: #eee;
  }

  .like-button,
  .dislike-button {
    background-color: transparent;
    color: #e3e1e1;
  }

  .like-button:hover,
  .dislike-button:hover {
    opacity: 0.7;
  }

  .liked,
  .disliked {
    color: #ff0000;
  }

  .views {
    color: #aaa;
  }

  .description {
    color: #ccc;
  }

  .toggle-button {
    background-color: #444;
    color: #fff;
  }

  .comments-section {
    color: #ccc;
  }

  .comment-item {
    background-color: #333;
    color: #ddd;
  }

  .comments .name-input {
    background-color: #444;
    color: #fff;
  }

  .comments textarea {
    background-color: #444;
    color: #fff;
  }
  .comments button {
    background-color: #444;
  }
  input::placeholder,
  textarea::placeholder {
    color: #a9a9a9;
    opacity: 1;
  }
}
